/** @jsxImportSource theme-ui */
import React from "react";
import TextField from "components/TextField";
import FileUpload from "components/FileUpload";
import Select from "components/Select";
import Button from "components/Button";
import useForm from "hooks/form";
import { Box, Text, Link } from "theme-ui";
import { pickBy } from "lodash";

const subjects = [
  {
    name: "Другая тема",
  },
  {
    name: "Транспорт",
  },
  {
    name: "Бизнес",
  },
  {
    name: "ЖКХ",
  },
  {
    name: "Спорт",
  },
  {
    name: "Культура",
  },
  {
    name: "Строительство и реконструкция",
  },
  {
    name: "Благоустройство",
  },
  {
    name: "Здоровье",
  },
  {
    name: "Безопасность",
  },
  {
    name: "Архитектура и градостроительство",
  },
  {
    name: "Туризм",
  },
  {
    name: "Образование",
  },
  {
    name: "Земля и имущество",
  },
];

const getModel = () => ({
  subject: "",
  name: "",
  contact: "",
  message: "",
  uploaded_files: [],
});

const Feedback = () => {
  const form = useForm({ url: "/feedback" });
  const [state, setState] = React.useState(getModel());
  const [sucsses, setSucsses] = React.useState(false);

  const setVal = (key, val) => setState((prev) => ({ ...prev, [key]: val }));
  const submit = () => {
    const params = pickBy(state);
    form.submit({ ...params, requestType: "appeal" });
  };

  React.useEffect(() => {
    if (form.response) {
      setState(getModel());
      setSucsses(true);
    }
  }, [form.response]);

  return (
    <>
      {form.response && sucsses ? (
        <Box sx={{ pb: [70, 170], pt: [83, 45] }}>
          <Text variant="h1">Обращение принято!</Text>
          <Box variant="layout.grid12" sx={{ mt: 30 }}>
            <Text
              as="div"
              sx={{ gridColumn: "1/6", color: "gray400" }}
              variant="text2"
            >
              Пишите ваши обращения, предложения, инициативы по улучшению нашего
              города
            </Text>
          </Box>
          <Box sx={{ mt: 60 }}>
            <Button
              onClick={() => {
                form.response = null;
                setSucsses(false);
              }}
            >
              Написать еще
            </Button>
          </Box>
        </Box>
      ) : (
        <Box variant="layout.grid12" sx={{ pb: [70, 170], pt: [83, 45] }}>
          <Box sx={{ gridColumn: "1/6" }}>
            <Text variant="h1" as="h1">
              Обращение
            </Text>
            <Text variant="text2" as="div" sx={{ mt: 30, color: "gray400" }}>
              Пишите ваши обращения, предложения, инициативы по улучшению нашего
              города
            </Text>
            <Box
              sx={{
                mt: [30, 60],
                display: "flex",
                flexDirection: "column",
                gap: 15,
              }}
            >
              <Select
                items={subjects}
                placeholder="Тематика"
                value={state.subject}
                error={form.validation.subject}
                onChange={(val) => setVal("subject", val)}
              />
              <TextField
                placeholder="ФИО"
                value={state.name}
                error={form.validation.name}
                onChange={(ev) => setVal("name", ev.target.value)}
              />
              <TextField
                placeholder="Контакт для связи"
                value={state.contact}
                error={form.validation.contact}
                onChange={(ev) => setVal("contact", ev.target.value)}
              />
              <TextField
                rows={8}
                multiline
                variant="outlined"
                placeholder="Сообщение"
                value={state.message}
                error={form.validation.message}
                onChange={(ev) => setVal("message", ev.target.value)}
              />
              <FileUpload
                error={form.validation.uploaded_files}
                onChange={(files) => {
                  setVal("uploaded_files", files);
                }}
              />
            </Box>
            <Box sx={{ paddingLeft: 36, mt: 30 }}>
              <Text sx={{ fontSize: 14, lineHeight: 1.7, fontWeight: 500 }}>
                Нажимая на кнопку «Отправить», я даю своё согласие на обработку
                персональных данных и соглашаюсь с условиями и{" "}
                <Link
                  sx={{
                    "&:hover": { textDecoration: "none" },
                  }}
                  href="/politika"
                >
                  политикой конфиденциальности
                </Link>
                .
              </Text>
            </Box>
            <Box sx={{ mt: 30, ml: 36 }}>
              <Button
                onClick={(ev) => {
                  ev.preventDefault();
                  if (!form.loading) {
                    submit();
                  }
                }}
              >
                Отправить
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Feedback;
