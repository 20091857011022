import React from "react";
import http from "lib/http";

const useForm = ({ url }) => {
  const [response, setResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [validation, setValidation] = React.useState({});

  const submit = async (data) => {
    const reqOptions = {
      method: "post",
      body: data,
    };

    setValidation({});
    setResponse(null);
    setLoading(true);
    setError(null);

    try {
      setResponse(await http(url, reqOptions));
    } catch (error) {
      const validationErrors = {};
      const allErrors = error?.message?.errors || {};

      Object.keys(allErrors).forEach((key) => {
        validationErrors[key] = allErrors[key].join(", ");
      });

      setValidation(validationErrors);
      setError(error);
    }

    setLoading(false);
  };

  return { submit, loading, error, response, validation };
};

export default useForm;
