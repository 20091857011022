/** @jsxImportSource theme-ui */
import RouterLink from "components/RouterLink";
import Breadcrumbs from "components/Breadcrumbs";
import Seo from "components/Seo";
import { humanDate } from "lib/helpers";
import { Box, Text } from "theme-ui";
import { useNewsPagination } from "store/news";

const NewsList = () => {
  const { data: news, meta = {} } = useNewsPagination({ count: 30 });
  const breadcrumbs = [
    {
      name: "Новости",
      href: "/news",
    },
  ];
  return (
    <Box sx={{ pb: [70, 170], pt: [83, 45] }}>
      <Seo seo={meta.seo} />
      <Breadcrumbs path={breadcrumbs} />
      <Box sx={{ mb: [62, 42], mt: [0, 30] }}>
        <Text variant="h1" as="h1">
          Новости
        </Text>
      </Box>
      <Box>
        {news.list.map((news) => (
          <RouterLink
            key={news.id}
            href={`/news/${news.id}`}
            sx={{
              display: "block",
              borderBottom: "2px solid",
              borderTop: "2px solid",
              borderColor: "gray300",
              py: 30,
              mt: -2,
              ":hover": {
                textDecoration: "none",
                position: "relative",
                zIndex: 1,
                borderColor: "text",
              },
            }}
          >
            <Box variant="layout.grid12">
              <Box
                sx={{
                  mb: 15,
                  display: ["block", "none"],
                  color: "gray400",
                }}
              >
                <Text variant="text1">{humanDate(news.publishedAt)}</Text>
              </Box>
              <Box sx={{ gridColumn: "1/9" }}>
                <Text variant="h3" as="h2">
                  {news.name}
                </Text>
                <Text
                  variant="text1"
                  as="div"
                  sx={{ mt: [15, 10], color: "gray400" }}
                >
                  {news.annotation}
                </Text>
              </Box>
              <Box
                sx={{
                  gridColumn: "11/13",
                  display: ["none", "block"],
                }}
              >
                <Text variant="text1">{humanDate(news.publishedAt)}</Text>
              </Box>
            </Box>
          </RouterLink>
        ))}
      </Box>
    </Box>
  );
};
export default NewsList;
