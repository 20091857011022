import Button from "components/Button";
import RouterLink from "components/RouterLink";
import Hr from "components/Hr";
import { useCookieInformerActions } from "actions";
import { Box, Text } from "theme-ui";

const CookieInformer = () => {
  const cookieInformerActions = useCookieInformerActions();
  return (
    <Box
      sx={{
        px: [20, 50],
        pt: 15,
        pb: [40, 20],
        bg: "primary",
      }}
    >
      <Hr />
      <Box
        sx={{
          gap: 24,
          mt: 17,
          display: "grid",
          gridTemplateColumns: ["auto", "1fr auto"],
          alignItems: "center",
        }}
      >
        <Box>
          <Text
            as="div"
            sx={{ maxWidth: ["100%", `${(850 / 1366) * 100}vw`] }}
            variant="text2"
          >
            Наш сайт использует файлы cookie и похожие технологии, предоставляя
            персональную информацию. При использовании данного сайта, Вы
            подтверждаете свое согласие на использование cookie файлов и
            обработку персональных общедоступных данных согласно{" "}
            <RouterLink
              href="/politika"
              sx={{ "&:hover": { textDecoration: "underline" } }}
            >
              Политике&nbsp;конфиденциальности
            </RouterLink>
            .
          </Text>
        </Box>
        <Box>
          <Button
            onClick={(ev) => {
              ev.preventDefault();
              cookieInformerActions.close();
            }}
            sx={{ pt: 12, pb: 12, px: 25 }}
          >
            Я согласен
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CookieInformer;
