/** @jsxImportSource theme-ui */
import ContentBuilder from "components/ContentBuilder";
import Seo from "components/Seo";
import { usePolicy } from "store/policy";
import { Box, Text } from "theme-ui";
import { safeJSONParse } from "lib/helpers";

const Policy = () => {
  const { data: policy = [], meta = {} } = usePolicy();
  const content = safeJSONParse(policy?.content) ?? [];

  return (
    <Box sx={{ pt: [83, 45] }}>
      <Seo seo={meta.seo} />
      <Text variant="h1">Политика конфиден&shy;циальности</Text>
      <Box sx={{ mt: 60 }}>
        {policy.content && (
          <ContentBuilder blocks={content} projectId={policy.id} />
        )}
      </Box>
    </Box>
  );
};

export default Policy;
