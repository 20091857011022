/** @jsxImportSource theme-ui */
import React from "react";
import { AspectRatio } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";

const Map = React.lazy(() => import("components/Map"));

const ProjectsMap = ({ projects = [] }) => {
  const center = CENTER;
  const border = POINTS;
  const placemarks = projects.map((project) => {
    return {
      onClick: () => {
        window.open(`/projects/${project.id}`);
      },
      geometry: [+project.geoLat, +project.geoLon],
      options: {
        iconLayout: "default#image",
        iconImageSize: [20, 20],
        iconImageOffset: [-20 / 2, -20],
        iconImageHref: require("icons/office-placemark.svg").default,
      },
      properties: {
        hintContent: project.name,
      },
      modules: ["geoObject.addon.hint"],
    };
  });

  return (
    <AspectRatio
      ratio={useResponsiveValue([1, 1266 / 560])}
      sx={{ borderRadius: 10, overflow: "hidden" }}
    >
      <React.Suspense fallback={null}></React.Suspense>
      <React.Suspense fallback={null}>
        <Map
          placemarks={placemarks}
          defaultState={{ zoom: 12, center: center }}
          border={border}
        />
      </React.Suspense>
    </AspectRatio>
  );
};

const POINTS = [
  [55.830558639118436, 49.22750563179121],
  [55.830848489735644, 49.200383134232645],
  [55.8324426293551, 49.19300169502364],
  [55.81471017113706, 49.1800412610637],
  [55.80262619164279, 49.18604940925705],
  [55.80475324521104, 49.18982595955003],
  [55.79445528724822, 49.204760499344935],
  [55.79590586934541, 49.19540495430099],
  [55.79814509026914, 49.19192826021358],
  [55.79364836881546, 49.18351685274285],
  [55.79229441506663, 49.18377434480831],
  [55.79127891873564, 49.18394600618526],
  [55.79040847214688, 49.184546821004574],
  [55.789586365765096, 49.18549095857783],
  [55.78895768442194, 49.18669258821651],
  [55.78552393728949, 49.19270073640984],
  [55.782225209239435, 49.19003998506707],
  [55.78723243459738, 49.1716159427263],
  [55.78645862454122, 49.16543613315597],
  [55.78703898353042, 49.1613162601091],
  [55.78887672971639, 49.16148792148605],
  [55.7930355180254, 49.16663776279465],
  [55.79874103680752, 49.166466101417704],
  [55.79903112550551, 49.16097293735518],
  [55.80193189312337, 49.162002905616916],
  [55.80840949060334, 49.14140354038255],
  [55.82281752894013, 49.16504145643642],
  [55.82610303213283, 49.165213117813394],
  [55.82900177413437, 49.172422895645425],
  [55.833349480392656, 49.16778803846769],
  [55.838966444766704, 49.169707224802934],
  [55.84215244264135, 49.15758754787306],
  [55.848792319383534, 49.158445854757815],
  [55.845824171668276, 49.17863361640451],
  [55.84592075069566, 49.181723521189646],
  [55.84621048633195, 49.18464176459786],
  [55.846983104092466, 49.18721668525216],
  [55.84901115233793, 49.190134928660356],
  [55.855967727134654, 49.19962800925516],
  [55.85070518518314, 49.20838273947979],
  [55.85875026018345, 49.24148941019247],
  [55.8565296179114, 49.24526596048545],
  [55.85266732776426, 49.24629592874713],
  [55.85063947110763, 49.25075912454794],
  [55.83630840888681, 49.251829643820315],
  [55.83398987012483, 49.24959804591991],
  [55.834762731803735, 49.2475381093965],
  [55.83293442254753, 49.242724874246825],
  [55.830558639118436, 49.22750563179121],
];

const CENTER = [55.824779, 49.171911];

export default ProjectsMap;
