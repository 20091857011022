/** @jsxImportSource theme-ui */
import { Helmet, HelmetProvider } from "react-helmet-async";

const Seo = ({ seo = {} }) => {
  return (
    <HelmetProvider>
      <Helmet>
        {seo.title && <title>{seo.title}</title>}
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
      </Helmet>
    </HelmetProvider>
  );
};

export default Seo;
