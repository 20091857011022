import { Box, Text } from "theme-ui";
import { useState, useRef } from "react";
import useClickOutside from "hooks/useClickOutside";
import { ReactComponent as ArrowIcon } from "icons/arrow-bottom.svg";

const Select = ({
  items = [],
  value,
  onChange,
  error,
  placeholder,
  ...props
}) => {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(value);
  const ref = useRef();

  useClickOutside(ref, () => {
    setOpened(false);
  });

  return (
    <Box sx={{ position: "relative" }} ref={ref}>
      <Text
        variant="text2"
        as="div"
        sx={{
          color: selected ? "text" : "gray400",
          ...textFieldSx,
          ...(error ? errorSx : {}),
        }}
        onClick={() => setOpened((opened) => !opened)}
      >
        {selected ? selected.name : placeholder}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            margin: "auto",
            right: 17,
            transition: "0.2s all",
            transform: `rotate(${opened ? "180deg" : "0deg"})`,
            height: 18,
            "& svg": {
              display: "block",
            },
          }}
        >
          <ArrowIcon />
        </Box>
      </Text>
      <Box
        sx={{
          position: "absolute",
          top: [48, 42],
          left: 0,
          right: 0,
          zIndex: 10,
          transition: "0.3s all",
          opacity: opened ? 1 : 0,
          pointerEvents: opened ? "auto" : "none",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            p: 20,
            pt: 15,
            pb: 5,
            bg: "primary",
            border: "2px solid",
            borderColor: "gray300",
            borderRadius: 10,
          }}
        >
          {items.map((item, index) => (
            <Box key={index}>
              <Text
                as="div"
                variant="text2"
                sx={{
                  color: "gray400",
                  cursor: "pointer",
                  "&:hover": { color: "text" },
                }}
                onClick={() => {
                  setSelected(item);
                  setOpened(false);
                  onChange(item.name);
                }}
              >
                {item.name}
              </Text>
              <Box sx={{ height: 1, bg: "gray300", mt: 10, mb: 15 }}></Box>
            </Box>
          ))}
        </Box>
      </Box>
      {error && <Text sx={{ color: "red100", fontSize: 12 }}>{error}</Text>}
    </Box>
  );
};

const textFieldSx = {
  boxShadow: "none",
  borderRadius: 10,
  border: "2px solid",
  borderColor: "gray300",
  fontSize: 14,
  fontWeight: 500,
  outline: "none",
  py: [13, 10],
  pl: 20,
  pr: 50,
  m: 0,
  position: "relative",
  transition: "0.2s all",
  "&:focus::placeholder": {
    color: "text",
  },
};

const errorSx = {
  borderColor: "red100",
  color: "red100",
  "&:focus": {
    borderColor: "gray300",
    color: "gray400",
  },
};

export default Select;
