import Slider from "components/Slider";
import AnimatedImage from "components/AnimatedImage";
import GallerySlider from "components/GallerySlider";
import { Box, Grid, Text } from "theme-ui";
import { useState } from "react";

const Gallery = ({ meta, value, projectId }) => {
  const { type } = meta.variant;

  const Component = components.get(type);
  const props = createProps(type, value);
  return Component ? (
    <Box mb={[100, 200]}>
      <Component value={props} projectId={projectId} />
    </Box>
  ) : null;
};

const SingleImage = ({ value }) => {
  return value ? (
    <Box>
      {value.name && (
        <Text as="div" variant="text1" sx={{ mb: 15 }}>
          {value.name}
        </Text>
      )}
      <AnimatedImage src={value.url} animateHover={true} />
    </Box>
  ) : null;
};

const ImageGrid = ({ value }) => {
  const [active, setActive] = useState(null);

  return value ? (
    <Box variant="layout.grid12">
      <Grid
        columns={["1fr", "1fr 1fr 1fr"]}
        sx={{ gridColumn: "4/16", rowGap: [30, 10], columnGap: [30, 15] }}
      >
        {value.map((image, index) => (
          <Box
            key={index}
            sx={{
              textDecoration: "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={() => {
              setActive(index);
            }}
          >
            {image.name && (
              <Text as="div" variant="text1" sx={{ mb: 15 }}>
                {image.name}
              </Text>
            )}
            <AnimatedImage
              ratio={309 / 226}
              src={image.url}
              animateHover={true}
            />
          </Box>
        ))}
      </Grid>
      {active !== null && (
        <GallerySlider
          slides={value}
          imageIndex={active}
          onClose={() => {
            setActive(null);
          }}
        />
      )}
    </Box>
  ) : null;
};

const DoubleImage = ({ value }) => {
  const [active, setActive] = useState(null);

  return value ? (
    <>
      <Grid
        columns={["1fr", "1fr 1fr"]}
        sx={{ rowGap: [30, 60], columnGap: [30, 10] }}
      >
        {value.map((image, index) => (
          <Box
            key={index}
            sx={{ textDecoration: "none", cursor: "pointer" }}
            onClick={() => setActive(index)}
          >
            {image.name && (
              <Text as="div" variant="text1" sx={{ mb: 15 }}>
                {image.name}
              </Text>
            )}
            <AnimatedImage src={image.url} animateHover={true} />
          </Box>
        ))}
      </Grid>
      {active !== null && (
        <GallerySlider
          slides={value}
          imageIndex={active}
          onClose={() => {
            setActive(null);
          }}
        />
      )}
    </>
  ) : null;
};

const ImagesSlider = ({ value, projectId }) => {
  const [active, setActive] = useState(null);

  return value ? (
    <Box>
      <Slider
        slides={value}
        options={{
          slidesPerView: "auto",
          spaceBetween: 10,
          cssMode: true,
          cursor: "pointer",
        }}
        onClick={(index) => {
          setActive(index);
        }}
      />
      {active !== null && (
        <GallerySlider
          slides={value}
          imageIndex={active}
          onClose={() => {
            setActive(null);
          }}
        />
      )}
    </Box>
  ) : null;
};

const components = new Map([
  ["double", DoubleImage],
  ["single", SingleImage],
  ["tiles", ImageGrid],
  ["slider", ImagesSlider],
]);

const createProps = (type, value) => {
  return {
    single: value[0],
    double: value.slice(0, 2),
    tiles: value,
    slider: value,
  }[type];
};

export default Gallery;
