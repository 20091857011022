import { Box, Text } from "theme-ui";

const Table = ({ value }) => {
  let gridSx = {};

  switch (value[0].length) {
    case 1:
      gridSx = {
        display: "block",
      };
      break;
    case 2:
      gridSx = {
        display: "grid",
        gridTemplateColumns: "30% 70%",
      };
      break;
    default:
      gridSx = {
        display: "flex",
      };
  }
  return (
    <Box sx={{ mb: [98, 198] }} variant="layout.grid12">
      <Box sx={{ gridColumn: "1 / 10" }}>
        {value.map((val, index) => {
          return (
            <Box
              key={index}
              sx={{
                borderTop: "2px solid",
                borderBottom: "2px solid",
                borderColor: val[0].attrs.header ? "text" : "gray300",
                py: [15, 30],
                marginBottom: -2,
                zIndex: val[0].attrs.header ? 10 : 0,
                position: "relative",
              }}
            >
              <Text
                variant="text1"
                sx={{
                  gap: 10,
                  ...gridSx,
                }}
              >
                {val.map((v, i) => (
                  <Box key={i} sx={{ flex: 1 }}>
                    {v.value}
                  </Box>
                ))}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Table;
