import RouterLink from "components/RouterLink";
import Button from "components/Button";
import { Box, Text } from "theme-ui";

const Error = () => (
  <Box
    sx={{
      height: "100vh",
      display: "flex",
      alignItems: "center",
    }}
  >
    <Box
      sx={{
        flex: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 60,
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: ["block", "grid"],
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: 10,
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box sx={{ gridColumn: "2/5", textAlign: ["center", "left"] }}>
          <Text variant="text1">Страница не найдена</Text>
        </Box>
        <Box
          sx={{
            gridColumn: "5/9",
            display: "flex",
            justifyContent: "center",
            mt: [15, 0],
          }}
        >
          <Box sx={{ fontSize: [73, 156], lineHeight: 1, fontWeight: 600 }}>
            404
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: ["block", "grid"],
          gridTemplateColumns: "repeat(12, 1fr)",
          height: "100%",
          alignItems: "center",
          gap: 10,
        }}
      >
        <Box
          sx={{ gridColumn: "5/9", display: "flex", justifyContent: "center" }}
        >
          <Box>
            <RouterLink
              href="/"
              sx={{
                "&:hover": { textDecoration: "none" },
              }}
            >
              <Button>На главную</Button>
            </RouterLink>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default Error;
