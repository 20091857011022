import Breadcrumbs from "components/Breadcrumbs";
import Button from "components/Button";
import ContentBuilder from "components/ContentBuilder";
import ProjectsMap from "components/ProjectsMap";
import RouterLink from "components/RouterLink";
import Seo from "components/Seo";
import { Box, Text } from "theme-ui";
import { useParams } from "react-router-dom";
import { useProjectsById } from "store/projects";
import { safeJSONParse } from "lib/helpers";

const Project = () => {
  const { data: project = {}, meta = {} } = useProjectsById(useParams().id);
  const content = safeJSONParse(project?.content) ?? [];

  const breadcrumbs = [
    {
      name: "Проекты",
      href: "/projects",
    },
    {
      name: project.name,
      href: `/projects/${project.id}`,
    },
  ];

  return (
    <Box sx={{ pt: [83, 45] }}>
      <Seo seo={meta.seo} />
      <Breadcrumbs path={breadcrumbs} />
      {project.name && (
        <Box sx={{ mt: [0, 30] }}>
          <Text variant="h1" as="h1">
            {project.name}
          </Text>
        </Box>
      )}
      {project.annotation && (
        <Box sx={{ my: [30, 60] }} variant="layout.grid12">
          <Text as="div" sx={{ gridColumn: "1/8" }} variant="h3">
            {project.annotation}
          </Text>
        </Box>
      )}
      <Box sx={{ mt: [30, 60] }}>
        {project.content && (
          <ContentBuilder blocks={content} projectId={project.id} />
        )}
      </Box>
      {project && (
        <Box sx={{ mt: [100, 200] }}>
          <ProjectsMap projects={[project]} />
        </Box>
      )}
      <Box
        sx={{
          mt: [30, 60],
          mb: [90, 170],
          display: "flex",
          justifyContent: "center",
        }}
      >
        <RouterLink href="/projects">
          <Button>Смотреть все проекты</Button>
        </RouterLink>
      </Box>
    </Box>
  );
};

export default Project;
