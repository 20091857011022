import { cookieInformerAtom } from 'state'
import { useSetRecoilState } from 'recoil'

const useCookieInformerActions = () => {
  const setCookieInformer = useSetRecoilState(cookieInformerAtom)

  const close = () => setCookieInformer(false)
  const open = () => setCookieInformer(true)

  return { close, open }
}

export { useCookieInformerActions }
