import { useResponsiveValue } from "@theme-ui/match-media";
import { Box, AspectRatio } from "theme-ui";

const Video = ({ value }) => {
  const ratio = useResponsiveValue([290 / 162, 942 / 528]);
  return (
    <Box variant="layout.grid12" sx={{ mb: [100, 200] }}>
      <Box sx={{ gridColumn: "1/10" }}>
        <AspectRatio ratio={ratio}>
          <iframe
            width="100%"
            height="auto"
            src={`https://www.youtube.com/embed/${value}`}
            frameBorder="0"
            title={value}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              top: 0,
              left: 0,
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          />
        </AspectRatio>
      </Box>
    </Box>
  );
};
export default Video;
