import { Box, Link } from "theme-ui";
import { ReactComponent as TgIcon } from "icons/tg.svg";
import { ReactComponent as VkIcon } from "icons/vk.svg";
import { useContacts } from "store/contacts";

const Socials = () => {
  const { data: contacts } = useContacts();
  const { tg, vk } = contacts.socials;

  return (
    <Box sx={{ display: "flex", gap: 5 }}>
      {tg && (
        <Link sx={sx} href={tg} target="_blank">
          <TgIcon sx={{ width: 13, height: 10 }} />
        </Link>
      )}
      {vk && (
        <Link sx={sx} href={vk} target="_blank">
          <VkIcon sx={{ width: 13, height: 10 }} />
        </Link>
      )}
    </Box>
  );
};

const sx = {
  transition: "0.2s all",
  display: "block",
  width: 22,
  height: 22,
  borderRadius: "50%",
  bg: "gray400",
  color: "primary",
  "& svg": {
    display: "block",
  },
  "&:hover": {
    color: "text",
  },
};

export default Socials;
