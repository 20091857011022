import RouterLink from "components/RouterLink";
import { ReactComponent as ArrowRightIcon } from "icons/arrow-right.svg";
import { Box, Text } from "theme-ui";

const Breadcrumbs = ({ path, ...props }) => (
  <Box sx={{ display: ["none", "block"], ...props.sx }} {...props}>
    {[{ name: "Главная", href: "/" }, ...path].map((crumb, index) => (
      <Box key={index} sx={{ display: "inline-block" }}>
        <Text
          variant="text2"
          sx={{
            color: index < path.length ? "gray300" : "gray400",
            transition: "0.2s all",
            cursor: "pointer",
            "&:hover": {
              color: "text",
            },
          }}
        >
          {index > 0 && (
            <Box
              as="span"
              sx={{
                px: "1ch",
                "& svg": {
                  width: 18,
                  height: 17,
                  mb: -3,
                  display: "inline-block",
                  color: "gray300",
                },
              }}
            >
              <ArrowRightIcon />
            </Box>
          )}
          <RouterLink href={crumb.href}>{crumb.name}</RouterLink>
        </Text>
      </Box>
    ))}
  </Box>
);

export default Breadcrumbs;
