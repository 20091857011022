/** @jsxImportSource theme-ui */
import { NavLink } from "react-router-dom";

const RouterLink = ({ children, href, sx, ...props }) => (
  <NavLink
    to={href}
    sx={{
      color: "inherit",
      textDecoration: "none",
      ...sx,
    }}
    {...props}
  >
    {children}
  </NavLink>
);

export default RouterLink;
