/** @jsxImportSource theme-ui */
import "swiper/swiper.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";

import { Box, Image, Text } from "theme-ui";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar, Autoplay, EffectFade } from "swiper/core";
import { ReactComponent as ArrowPrevIcon } from "icons/arrow-prev.svg";
import { ReactComponent as ArrowNextIcon } from "icons/arrow-next.svg";
import { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "icons/close-modal.svg";

SwiperCore.use([Scrollbar, Autoplay, EffectFade]);

const GallerySlider = ({ slides, imageIndex = 0, onClose = () => {} }) => {
  const [ready, setReady] = useState(false);
  const [swiper, setSwiper] = useState();
  const [active, setActive] = useState(0);

  const onSlideChange = () => {
    setActive(swiper.realIndex);
  };

  const addZeroPrefix = (value) => {
    if (value <= 9) return "0" + value;
    return value;
  };

  useEffect(() => {
    if (swiper) swiper.slideTo(imageIndex);
  }, [swiper, imageIndex]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: [72, 0],
        left: 0,
        bottom: 0,
        right: 0,
        bg: "primary",
        zIndex: 99,
      }}
    >
      <Box
        onClick={onClose}
        sx={{
          width: 26,
          height: 26,
          position: "absolute",
          top: [20, 40],
          right: [0, 40],
          left: [0, "auto"],
          margin: "auto",
          color: ["text", "primary"],
          transition: "0.2s all",
          zIndex: 9,
          "&:hover": {
            color: ["gray300", "text"],
          },
        }}
      >
        <CloseIcon />
      </Box>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: ["column-reverse", "column"],
          pt: ["66px", 0],
        }}
      >
        <Box
          sx={{
            bg: ["primary", "gray300"],
            flex: "auto",
            overflow: "hidden",
            position: "relative",
            opacity: ready ? 1 : 0,
            transition: "0.2s all",
            ".swiper-container, .swiper-wrapper, .swiper-slide": {
              height: "100%",
            },
          }}
        >
          <Swiper
            scrollbar={{ draggable: true, el: `#scrollbar` }}
            effect="fade"
            speed={800}
            fadeEffect={{
              crossFade: true,
            }}
            loop={false}
            onSlideChange={onSlideChange}
            slidesPerView={1}
            onImagesReady={(swiper) => {
              if (swiper) {
                swiper.update();
                swiper.scrollbar.init();
                setReady(true);
              }
            }}
            onSwiper={setSwiper}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <Image
                  sx={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={slide.url}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {active !== 0 && (
            <Box
              sx={{
                position: "absolute",
                left: 13,
                ...arrowSx,
              }}
              onClick={() => swiper.slidePrev()}
            >
              <ArrowPrevIcon />
            </Box>
          )}
          {active !== slides.length - 1 && (
            <Box
              sx={{ position: "absolute", right: 13, ...arrowSx }}
              onClick={() => swiper.slideNext()}
            >
              <ArrowNextIcon />
            </Box>
          )}
        </Box>
        <Box sx={{ px: [16, 40], pt: 20, pb: [16, 20] }}>
          <Box
            id="scrollbar"
            sx={{
              width: "100%",
              height: 2,
              mt: [15, 30],
              bg: "gray300",
              "& .swiper-scrollbar-drag": {
                height: 3,
                bg: "text",
                top: -0.5,
                borderRadius: 0,
              },
            }}
          />
          <Box sx={{ mt: 9, display: "flex" }}>
            <Box sx={{ flex: "none" }}>
              <Text variant="text1">{addZeroPrefix(active + 1)}</Text>
            </Box>
            <Box sx={{ flex: "auto", textAlign: "center" }}>
              <Text variant="text1">{slides[active].name}</Text>
            </Box>
            <Box sx={{ flex: "none" }}>
              <Text variant="text1">{addZeroPrefix(slides.length)}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const arrowSx = {
  top: 0,
  bottom: 0,
  margin: "auto",
  zIndex: 10,
  color: "white",
  transition: "0.2s all",
  height: 38,
  cursor: "pointer",
  display: ["none", "block"],
  "& svg": {
    display: "block",
    height: 38,
    width: 99,
  },
  "&:hover": {
    color: "text",
  },
};

export default GallerySlider;
