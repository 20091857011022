import { Link, Text } from "theme-ui";

const Dev = () => (
  <Link
    href="https://itef.ru/"
    sx={{
      color: "gray400",
      transition: "0.2s all",
      display: "block",
      textDecoration: "none",
      "&:hover": { color: "text" },
    }}
  >
    <Text variant="text2">
      Сайт от{" "}
      <Text as="span" sx={{ color: "text" }}>
        ITEF
      </Text>
    </Text>
  </Link>
);

export default Dev;
