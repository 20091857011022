const theme = {
  config: {
    initialColorModeName: "light",
    useColorSchemeMediaQuery: true,
  },
  breakpoints: ["960px"],
  styles: {
    root: {
      overflowX: "hidden",
      fontFamily: "body",
      bg: "primary",
      "select, [type=text], [type=password],textarea": {
        fontFamily: "body",
      },
      "& *": {
        WebkitFontSmoothing: "subpixel-antialiased",
      },
      "& a": {
        color: "inherit",
        textDecorationSkip: "objects",
        textDecorationSkipInk: "none",
      },
    },
  },
  space: [],
  fonts: {
    body: '"Inter", sans-serif',
    heading: "inherit",
    monospace: "monospace",
  },
  colors: {
    primary: "#000",
    gray500: "#888",
    gray400: "#D5D6D3",
    gray300: "#D5D6D3",
    red100: "#DA2C2C",
    text: "#fff",
    secondary: "#F4E347",
    modes: {
      light: {
        primary: "#fff",
        gray500: "#888",
        gray400: "#686868",
        gray300: "#D5D6D3",

        text: "#000",
        secondary: "#F4E347",
        red100: "#DA2C2C",
      },
    },
  },
  layout: {
    container: {
      maxWidth: 1440,
      margin: "auto",
      px: [20, 40],
    },
    grid2: {
      gap: [0, 10],
      display: ["block", "grid"],
      gridTemplateColumns: ["auto", "1fr 1fr"],
    },
    grid12: {
      gap: 10,
      display: ["block", "grid"],
      gridTemplateColumns: "repeat(12, 1fr)",
    },
  },
  text: {
    default: {},
    h1: {
      fontSize: [36, 73],
      lineHeight: 1,
      fontWeight: 600,
      letterSpacing: -1,
    },
    h2: {
      fontSize: [26, 41],
      fontWeight: 500,
      letterSpacing: -1,
      lineHeight: 1.36,
    },
    h3: {
      fontSize: [20, 26],
      fontWeight: 500,
      lineHeight: [1.2, 1.15],
    },
    text1: {
      fontSize: [14, 20],
      fontWeight: 500,
      lineHeight: [1.7, 1.2],
    },
    text2: {
      fontSize: [12, 14],
      fontWeight: 500,
      lineHeight: 1.25,
    },
    text3: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "150%",
      letterSpacing: "0.01em",
      textTransform: "uppercase",
    },
    text4: {
      fontSize: [14, 25],
      textTransform: "uppercase",
      fontWeight: [700, 600],
    },
    text5: {
      fontSize: [14, 16],
      textTransform: ["none", "uppercase"],
      fontWeight: [500, 600],
    },
  },
};

export default theme;
