import AnimatedImage from "components/AnimatedImage";
import { Box, Text } from "theme-ui";

const TextWithImage = ({ value, type }) => {
  const Component = components.get(type);
  return Component ? <Component value={value} /> : null;
};

const TextWithLeftImage = ({ value }) => {
  const { image, text } = value;
  return (
    <Box variant="layout.grid2" sx={{ mb: [100, 200] }}>
      <Box>
        {image && image.url && (
          <AnimatedImage src={image.url} animateHover={true} />
        )}
      </Box>
      <Box sx={{ mt: [30, 0], pl: [0, 30] }}>
        {text && <Text variant="text1">{text}</Text>}
      </Box>
    </Box>
  );
};

const TextWithRightImage = ({ value }) => {
  const { image, text } = value;
  return (
    <Box variant="layout.grid2" sx={{ mb: [100, 200] }}>
      <Box sx={{ pr: [0, 30] }}>
        {text && <Text variant="text1">{text}</Text>}
      </Box>
      <Box sx={{ mt: [30, 0] }}>
        {image && image.url && (
          <AnimatedImage src={image.url} animateHover={true} />
        )}
      </Box>
    </Box>
  );
};

const components = new Map([
  ["textWithLeftImage", TextWithLeftImage],
  ["textWithRightImage", TextWithRightImage],
]);

export default TextWithImage;
