/** @jsxImportSource theme-ui */
import Itf from "components/Itf";
import Switch from "components/Switch";
import { Box, Text, Link } from "theme-ui";
import { useContacts } from "store/contacts";
import { ReactComponent as ArrowIcon } from "icons/arrow-top.svg";

const Footer = ({ sx, ...props }) => {
  const { data: contacts } = useContacts();
  const { tg, vk } = contacts.socials;
  const { html, prefix, phone } = contacts.phone;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box
      as="footer"
      sx={{
        pb: [30, 50],
        pt: [10, 30],
        ...sx,
      }}
      {...props}
    >
      <Box
        sx={{
          display: ["block", "grid"],
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: 10,
        }}
      >
        <Box sx={{ gridColumn: "1/11" }}>
          <Text as="div" variant="h1">
            Салимгараев
            <br /> Радик
            <br /> Ваккасович
          </Text>
          <Text as="div" variant="text1" sx={{ mt: [5, 15], color: "gray400" }}>
            Депутат Казанской Городской Думы
          </Text>
        </Box>
        <Box
          sx={{
            gridColumn: "11/13",
            display: "flex",
            flexDirection: "column",
            gap: 15,
            mt: [25, 0],
          }}
        >
          <Link
            href={`tel:${html}`}
            sx={{
              textDecoration: "none",
              color: "gray400",
              fontSize: [14, 20],
              fontWeight: 500,
              "&:hover": {
                color: "text",
              },
            }}
          >
            <Text as="span" sx={{ fontSize: [12, 14] }}>
              {prefix}
            </Text>{" "}
            {phone}
          </Link>
          <Link
            href="https://kzn.ru/meriya/kazanskaya-gorodskaya-duma/obratitsya-k-deputatu/?dep=485333"
            target="_blank"
            sx={linkSx}
          >
            <Text as="div" variant="text2">
              Интернет-приемная
            </Text>
          </Link>
          <Link href="/feedback" sx={linkSx}>
            <Text as="div" variant="text2">
              Написать письмо
            </Text>
          </Link>
          <Link href={vk} target="_blank" sx={linkSx}>
            <Text as="div" variant="text2">
              Вконтакте
            </Text>
          </Link>
          <Link href={tg} target="_blank" sx={linkSx}>
            <Text as="div" variant="text2">
              Телеграм
            </Text>
          </Link>
          <Box
            sx={{
              display: ["flex", "none"],
              justifyContent: "space-between",
              alignItems: "center",
              mt: -4,
            }}
          >
            <Itf />
            <Switch />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: ["none", "grid"],
          gridTemplateColumns: "repeat(3, 1fr)",
          mt: 30,
        }}
      >
        <Box sx={{ display: "flex", gap: 15, alignItems: "center" }}>
          <Switch />
          <Itf />
        </Box>
        <Box
          onClick={scrollToTop}
          sx={{
            gap: 5,
            alignItems: "center",
            color: "gray400",
            transition: "0.2s all",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            "&:hover": { color: "text" },
          }}
        >
          <Text as="div" variant="text2">
            Вернуться наверх
          </Text>
          <Box sx={{ "& svg": { display: "block" } }}>
            <ArrowIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const linkSx = {
  fontSize: 14,
  color: "gray400",
  fontWeight: 500,
  lineHeight: 1.71,
  display: "block",
  textDecoration: "none",
  "&:hover": {
    color: "text",
  },
};

export default Footer;
