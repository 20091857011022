/** @jsxImportSource theme-ui */
import React from "react";
import Button from "components/Button";
import Itf from "components/Itf";
import Switch from "components/Switch";
import RouterLink from "components/RouterLink";
import { useContacts } from "store/contacts";
import { Box, Image, Link } from "theme-ui";

const HeaderSm = () => {
  const [open, setOpen] = React.useState(false);
  const { data: contacts } = useContacts();
  const { tg, vk } = contacts.socials;
  const { html, prefix, phone } = contacts.phone;

  React.useEffect(() => {
    document.getElementsByTagName("html")[0].style.overflow = open
      ? "hidden"
      : "visible";
  }, [open]);

  return (
    <Box>
      <Box />
      <Box
        sx={{
          zIndex: 101,
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bg: "primary",
          p: 15,
        }}
      >
        <RouterLink href="/">
          <Box
            sx={{
              display: "flex",
              gap: 12,
              alignItems: "center",
            }}
          >
            <Box>
              <Image
                src={require("images/avatar.jpg").default}
                sx={{
                  width: 38,
                  height: 38,
                  borderRadius: "100%",
                }}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  lineHeight: 1.15,
                }}
              >
                Салимгараев
                <br />
                Радик
              </Box>
            </Box>
          </Box>
        </RouterLink>

        <Box
          onClick={() => setOpen((open) => !open)}
          sx={{ fontSize: 20, fontWeight: 500 }}
        >
          {open ? "Закрыть" : "Меню"}
        </Box>
      </Box>
      {open && (
        <Box
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "fixed",
            bg: "primary",
            zIndex: 100,
            pt: 83,
            px: 15,
            pb: 30,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 15 }}>
              <RouterLink
                href="/projects"
                sx={linkSx}
                activeClassName="selected"
              >
                Проекты
              </RouterLink>
              <RouterLink href="/news" sx={linkSx} activeClassName="selected">
                Новости
              </RouterLink>
              <RouterLink href="/biography" sx={linkSx} activeClassName="selected">
                Биография
              </RouterLink>
            </Box>
            <Box
              sx={{ mt: 30, display: "flex", flexDirection: "column", gap: 20 }}
            >
              <RouterLink href="/feedback">
                <Button>Напишите мне</Button>
              </RouterLink>
              <Link href={`tel:${html}`} target="_blank" sx={btnLinkSx}>
                <Button>
                  {prefix} {phone}
                </Button>
              </Link>
              <Link
                href="https://kzn.ru/meriya/kazanskaya-gorodskaya-duma/obratitsya-k-deputatu/?dep=485333"
                target="_blank"
                sx={btnLinkSx}
              >
                <Button>Интернет-приемная</Button>
              </Link>
              {vk && (
                <Link href={vk} target="_blank" sx={btnLinkSx}>
                  <Button>Вконтакте</Button>
                </Link>
              )}
              {tg && (
                <Link href={tg} target="_blank" sx={btnLinkSx}>
                  <Button>Телеграм</Button>
                </Link>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              flex: "none",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Itf />
            </Box>
            <Box>
              <Switch />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const btnLinkSx = {
  textDecoration: "none",
};

const linkSx = {
  fontSize: 30,
  color: "text",
  fontWeight: 500,
  lineHeight: 1.2,
  display: "block",
  "&:hover": {
    textDecoration: "underline",
  },
};

export default HeaderSm;
