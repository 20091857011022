import { atom } from 'recoil'
import { localStorageEffect } from 'lib/helpers'

const cookieInformerAtom = atom({
  key: 'cookie',
  default: true,
  effects: [localStorageEffect('cookie-informer')]
})

export { cookieInformerAtom }
