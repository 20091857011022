/** @jsxImportSource theme-ui */
import { motion } from "framer-motion/dist/framer-motion";
import { Box } from "theme-ui";

const AnimatedImage = ({ src, animateHover = false }) => {
  return (
    <motion.div
      whileInView={{ y: 0, opacity: 1 }}
      initial={{ y: 50, opacity: 0 }}
      sx={{
        width: "100%",
        height: "100%",
      }}
      transition={{
        opacity: {
          delay: 0.1,
          duration: 1.5,
          ease: "anticipate",
        },
        y: {
          delay: 0.1,
          duration: 1.5,
          ease: "anticipate",
        },
      }}
    >
      <Box sx={{ borderRadius: 10, overflow: "hidden" }}>
        <motion.div
          sx={{ height: "100%" }}
          whileHover={{ scale: animateHover ? 1.03 : 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <motion.img
            src={src}
            initial={{ filter: "grayscale(1)" }}
            whileInView={{ filter: "grayscale(0)" }}
            transition={{
              delay: 0.1,
              filter: {
                duration: 0.5,
                ease: [0, 0.55, 0.45, 1],
              },
            }}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              display: "block",
              transform: "scale(1)",
            }}
          />
        </motion.div>
      </Box>
    </motion.div>
  );
};

export default AnimatedImage;
