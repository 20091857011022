/** @jsxImportSource theme-ui */
import Button from "components/Button";
import RouterLink from "components/RouterLink";
import Headroom from "react-headroom";
import Socials from "components/Socials";
import { useContacts } from "store/contacts";
import { Box, Link, Image } from "theme-ui";

const HeaderMd = () => {
  const { data: contacts } = useContacts();
  const { html, prefix, phone } = contacts.phone;

  return (
    <Headroom>
      <Box
        as="header"
        sx={{
          px: 50,
          py: 14,
          bg: "primary",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          alignItems: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 20,
              alignItems: "center",
            }}
          >
            <RouterLink href="/">
              <Box
                sx={{
                  display: "flex",
                  gap: 12,
                  alignItems: "center",
                }}
              >
                <Box>
                  <Image
                    src={require("images/avatar.jpg").default}
                    sx={{
                      width: 52,
                      height: 52,
                      borderRadius: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      fontSize: 26,
                      fontWeight: 500,
                      lineHeight: 1.15,
                    }}
                  >
                    Салимгараев Радик
                  </Box>
                  <Box
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      lineHeight: 1.7,
                      color: "gray400",
                    }}
                  >
                    Депутат Казанской Городской Думы
                  </Box>
                </Box>
              </Box>
            </RouterLink>

            <Box>
              <Socials />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 20,
            }}
          >
            <Box>
              <RouterLink href="/projects" sx={linkSx}>
                Проекты
              </RouterLink>
            </Box>
            <Box>
              <RouterLink href="/news" sx={linkSx}>
                Новости
              </RouterLink>
            </Box>
            <Box>
              <RouterLink href="/biography" sx={linkSx}>
                Биография
              </RouterLink>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 60,
          }}
        >
          <Box>
            <Link
              href={`tel:${html}`}
              sx={{
                fontSize: 20,
                fontWeight: 500,
                textTransform: "uppercase",
                color: "gray400",
                textDecoration: "none",
              }}
            >
              <Box as="span" sx={{ fontSize: 14 }}>
                {prefix}
              </Box>{" "}
              {phone}
            </Link>
          </Box>
          <Box>
            <Link href="/feedback" sx={{ textDecoration: "none" }}>
              <Button>Напишите мне</Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Headroom>
  );
};

const linkSx = {
  fontSize: 20,
  color: "text",
  fontWeight: 500,
  lineHeight: 1.2,
  "&:hover": {
    textDecoration: "underline",
  },
};

export default HeaderMd;
