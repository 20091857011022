import "swiper/swiper.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";

import { Box, Image, Text } from "theme-ui";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar, Autoplay } from "swiper/core";
import { ReactComponent as ArrowPrevIcon } from "icons/arrow-prev.svg";
import { ReactComponent as ArrowNextIcon } from "icons/arrow-next.svg";
import { useState, useRef } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { nanoid } from "nanoid";

SwiperCore.use([Scrollbar, Autoplay]);

const Slider = ({ slides, options, onClick = () => {} }) => {
  const [swiper, setSwiper] = useState();
  const [active, setActive] = useState(0);
  const [controlsVisibility, setControlsVisibility] = useState(false);
  const scrollbar = useRef();
  const scrollbarId = "slider-scrollbar-" + nanoid();

  const onSlideChange = () => {
    setActive(swiper.realIndex);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          borderRadius: 10,
          "& .swiper-slide": {
            width: "auto",
          },
        }}
      >
        <Swiper
          scrollbar={{ draggable: true, el: `#${scrollbarId}` }}
          loop={false}
          onSlideChange={onSlideChange}
          onImagesReady={(swiper) => {
            if (scrollbar.current && scrollbar.current.offsetParent) {
              setControlsVisibility(true);
            }
            if (swiper) {
              swiper.update();
              swiper.scrollbar.init();
              swiper.slideTo(0, 0, false);
            }
          }}
          onSwiper={setSwiper}
          {...options}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <Box
                onClick={() => {
                  onClick(index);
                }}
                sx={{
                  borderRadius: 10,
                  height: [142, 460],
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <motion.div
                  initial="initial"
                  whileHover="animate"
                  style={{ width: "100%", height: "100%" }}
                >
                  <motion.div
                    variants={slideMotion}
                    transition={{ duration: 1.5, ease: "easeOut" }}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Image
                      sx={{
                        display: "block",
                        width: "auto",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={slide.url}
                    />
                    <motion.div
                      variants={filterMotion}
                      transition={{ duration: 0.5 }}
                    >
                      <Box
                        sx={{
                          pointerEvents: "none",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                          bg: "rgba(0, 0, 0, .2)",
                        }}
                      ></Box>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </Box>
              {slide.name && (
                <Text as="div" variant="text1" sx={{ mt: 15 }}>
                  {slide.name}
                </Text>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        {active !== 0 && (
          <Box
            sx={{
              position: "absolute",
              left: 13,
              ...arrowSx,
            }}
            onClick={() => swiper.slidePrev()}
          >
            <ArrowPrevIcon />
          </Box>
        )}
        {active !== slides.length - 1 && (
          <Box
            sx={{ position: "absolute", right: 13, ...arrowSx }}
            onClick={() => swiper.slideNext()}
          >
            <ArrowNextIcon />
          </Box>
        )}
      </Box>
      <Box
        ref={scrollbar}
        id={scrollbarId}
        sx={{
          width: "100%",
          height: 2,
          mt: [15, 30],
          bg: "gray400",
          "& .swiper-scrollbar-drag": {
            height: 3,
            bg: "text",
            top: -0.5,
            borderRadius: 0,
          },
        }}
      />
      {controlsVisibility && (
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mt: [5, 10] }}
        >
          <Text variant="h3" as="div">
            1
          </Text>
          <Text variant="h3" as="div">
            {slides.length}
          </Text>
        </Box>
      )}
    </Box>
  );
};

const arrowSx = {
  top: 0,
  bottom: 0,
  margin: "auto",
  zIndex: 10,
  color: "white",
  transition: "0.2s all",
  height: 38,
  cursor: "pointer",
  display: ["none", "block"],
  "& svg": {
    display: "block",
    height: 38,
    width: 99,
  },
  "&:hover": {
    color: "text",
  },
};

const slideMotion = {
  initial: {
    scale: 1,
  },
  animate: {
    scale: 1.05,
  },
};

const filterMotion = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export default Slider;
