import Layout from "components/Layout";
import ScrollTop from "components/ScrollTop";
import routes from "routes";
import theme from "theme";
import http from "lib/http";
import { useSettings } from "store/settings";
import { SWRConfig } from "swr";
import { ThemeProvider } from "theme-ui";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";

import { useNewsFindAll } from "store/news";
import { useContacts } from "store/contacts";

const App = () => {
  return (
    <RecoilRoot>
      <SWRConfig value={{ fetcher: http, revalidateOnFocus: true }}>
        <SeoHead />
        <PrefetchStores />
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              {routes.map(
                (
                  { Component, HeaderProps, layout = true, ...props },
                  index
                ) => (
                  <Route {...props} key={index}>
                    {layout ? (
                      <Layout HeaderProps={HeaderProps}>
                        <Component />
                      </Layout>
                    ) : (
                      <Component />
                    )}
                  </Route>
                )
              )}
            </Switch>
            <ScrollTop />
          </Router>
        </ThemeProvider>
      </SWRConfig>
    </RecoilRoot>
  );
};

const PrefetchStores = () => {
  const prefetch = [useNewsFindAll, useContacts];
  prefetch.forEach((callback) => callback());
  return null;
};

const SeoHead = () => {
  const { data: settings } = useSettings();

  const defaultText = "Сайт депутата Салимгараева Радика Ваккасовича";

  const title = settings?.defaultMeta?.title || defaultText;
  const description = settings?.defaultMeta?.description || defaultText;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    </HelmetProvider>
  );
};

export default App;
