/** @jsxImportSource theme-ui */
import React from "react";
import { Box } from "theme-ui";
import { useColorMode } from "theme-ui";
import { ReactComponent as ThemeDarkIcon } from "icons/theme-dark.svg";
import { ReactComponent as ThemeLightIcon } from "icons/theme-light.svg";

const Switch = () => {
  const [colorMode, setColorMode] = useColorMode();

  const toggleColorMode = React.useCallback(() => {
    if (colorMode === "light") setColorMode("dark");
    else setColorMode("light");
  }, [colorMode, setColorMode]);

  return (
    <Box
      onClick={toggleColorMode}
      sx={{ "& svg": { display: "block", cursor: "pointer" } }}
    >
      {colorMode === "light" ? (
        <ThemeDarkIcon sx={{ width: 23, height: 23 }} />
      ) : (
        <ThemeLightIcon sx={{ width: 23, height: 23 }} />
      )}
    </Box>
  );
};

export default Switch;
