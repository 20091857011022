/** @jsxImportSource theme-ui */
import Breadcrumbs from "components/Breadcrumbs";
import Button from "components/Button";
import RouterLink from "components/RouterLink";
import Slider from "components/Slider";
import Seo from "components/Seo";
import { useParams } from "react-router-dom";
import { humanDate } from "lib/helpers";
import { useNewsById } from "store/news";
import { Box, Text } from "theme-ui";

const News = () => {
  const { data: news, meta = {} } = useNewsById(useParams().id);
  const breadcrumbs = [
    {
      name: "Новости",
      href: "/news",
    },
    {
      name: news?.name,
      href: `/news/${useParams().id}`,
    },
  ];
  return (
    <Box sx={{ pb: [70, 170], pt: [83, 45] }}>
      <Seo seo={meta.seo} />
      <Breadcrumbs path={breadcrumbs} />
      {news && (
        <Box sx={{ mt: [0, 30] }}>
          <Text variant="h1" as="h1">
            {news.name}
          </Text>
          <Text
            as="div"
            sx={{
              mt: [30, 60],
              color: "gray400",
            }}
            variant="text2"
          >
            {humanDate(news.publishedAt)}
          </Text>
          <Box variant="layout.grid12" sx={{ mt: [24, 30] }}>
            <Text
              sx={{
                gridColumn: "1/8",
                img: {
                  width: "100%",
                },
              }}
              as="div"
              variant="text1"
              dangerouslySetInnerHTML={{ __html: news.body }}
            />
          </Box>
        </Box>
      )}
      {news?.files?.length > 0 && (
        <Box sx={{ mt: 60 }}>
          <Slider
            slides={news.files}
            options={{
              slidesPerView: "auto",
              spaceBetween: 10,
              cssMode: true,
              autoplay: {
                delay: 5000,
                disableOnInteraction: true,
              },
            }}
          />
        </Box>
      )}
      <Box sx={{ mt: [30, 60] }}>
        <RouterLink href="/news">
          <Button>Смотреть все новости</Button>
        </RouterLink>
      </Box>
    </Box>
  );
};

export default News;
