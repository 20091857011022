import useSWR from "swr";
import { createResponse } from "lib/helpers";

export const useContacts = () => {
  let offices;

  const coordinates = [53.549446, 49.311813];
  const { data, error } = useSWR("/offices");
  const socials = {
    tg: "https://t.me/salimgaraevradik",
    vk: "https://vk.com/salimgaraev2020",
  };
  const phone = {
    html: "+78435050557",
    prefix: "(843)",
    phone: "505 05 57",
  };

  if (!error && data?.data) {
    offices = data?.data;
  }

  return createResponse({
    data: {
      data: {
        socials,
        phone,
        offices,
        coordinates,
        placemarks: [
          {
            geometry: coordinates,
            options: [
              {
                iconLayout: "default#image",
                iconImageSize: [40, 51],
                iconImageOffset: [-40 / 2, -51],
                iconImageHref: require("icons/office-placemark.svg").default,
              },
              {
                iconLayout: "default#image",
                iconImageSize: [80, 102],
                iconImageOffset: [-80 / 2, -102],
                iconImageHref: require("icons/office-placemark.svg").default,
              },
            ],
          },
        ],
      },
    },
    error,
  });
};
