import { toCamelCase, toSnakeCase } from "case-converter";

const baseUrl = process.env.REACT_APP_API_URL;

const Alias = "dep_srv";
const Company = "dep_srv";

const defaults = {
  json: true,
  headers: {
    Alias,
    Company,
    "Content-Type": "application/json",
  },
};

export default async function http(url, params) {
  const settings = { ...defaults, ...params };

  // TODO: check submit / patch / post / etc
  if (settings.body && settings.method === "post") {
    const body = toSnakeCase(settings.body);
    settings.body = JSON.stringify(body);
  }

  const dst = url.startsWith("/") ? baseUrl + url : url;
  const res = await fetch(dst, settings);

  if (!res.ok) {
    const error = new Error("Ошибка выполения запроса");

    try {
      error.message = toCamelCase(await res.json());
    } catch (error) {
      error.message = error;
    }

    error.status = res.status;
    throw error;
  }

  return Promise.resolve().then(async () => {
    if (!settings.json) return await res.text();
    return toCamelCase(await res.json());
  });
}
