const routes = [
  {
    path: "/",
    exact: true,
    Component: require("./Home").default,
  },
  {
    path: "/projects",
    exact: true,
    Component: require("./ProjectList").default,
  },
  {
    path: "/projects/:id",
    exact: true,
    Component: require("./Project").default,
  },
  {
    path: "/news",
    exact: true,
    Component: require("./NewsList").default,
  },
  {
    path: "/news/:id",
    exact: true,
    Component: require("./News").default,
  },
  {
    path: "/feedback",
    exact: true,
    Component: require("./Feedback").default,
  },
  {
    path: "/politika",
    exact: true,
    Component: require("./Policy").default,
  },
  {
    path: "/biography",
    exact: true,
    Component: require("./Biography").default,
  },
  {
    path: "*",
    Component: require("./Error").default,
    layout: false,
  },
];

export default routes;
