import { Box } from "theme-ui";

const Button = ({ children, sx = {}, onClick = () => {} }) => (
  <Box
    onClick={onClick}
    sx={{
      fontSize: 20,
      lineHeight: 1.2,
      fontWeight: 500,
      px: 30,
      py: 14,
      border: "2px solid",
      borderColor: "currentColor",
      borderRadius: 30,
      transition: "0.3s all",
      width: "fit-content",
      whiteSpace: "nowrap",
      "&:hover": {
        bg: "text",
        color: "white",
      },
      ...sx,
    }}
  >
    {children}
  </Box>
);

export default Button;
