import { Box, Text } from "theme-ui";
import { sanitizeHTML } from "lib/helpers";

export const List = ({ value, meta }) => {
  const Component = listComponents.get(meta.variant.type);
  return Component ? <Component value={value} /> : null;
};

export const Heading = ({ value, meta }) => {
  const Component = components.get(meta.variant);
  return Component ? <Component value={value} /> : null;
};

export const TextBlock = ({ value }) => {
  const html = sanitizeHTML(value);
  return (
    <Box variant="layout.grid2" mb={[30, 60]}>
      <Box />
      <Text variant="text1" dangerouslySetInnerHTML={{ __html: html }}></Text>
    </Box>
  );
};

const H2 = ({ value }) => (
  <Box mb={[30, 60]}>
    <Text variant="h2">{value}</Text>
  </Box>
);
const H3 = ({ value }) => (
  <Box variant="layout.grid2" mb={[15, 30]}>
    <Text variant="h3">{value}</Text>
  </Box>
);

const Ul = ({ value }) => (
  <Box variant="layout.grid2" sx={{ mb: [100, 200] }}>
    <Box></Box>
    <Box>
      <Box
        as="ul"
        sx={{
          listStyle: "none",
          margin: 0,
          padding: 0,
          display: "flex",
          flexDirection: "column",
          gap: 15,
        }}
      >
        {value.map((name, index) => (
          <Box
            as="li"
            key={index}
            sx={{
              margin: 0,
              padding: 0,
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Box sx={{ pr: 10 }}>
              <Box sx={{ width: 8, height: 8, bg: "text", mt: -10 }}></Box>
            </Box>
            <Text as="div" variant="text1">
              {name}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
);

const Ol = ({ value }) => {
  return (
    <Box variant="layout.grid2" sx={{ mb: [100, 200] }}>
      <Box></Box>
      <Box>
        <Box
          as="ol"
          sx={{
            listStyle: "none",
            margin: 0,
            padding: 0,
            display: "flex",
            flexDirection: "column",
            gap: 15,
          }}
        >
          {value.map((name, index) => (
            <Box
              as="li"
              key={index}
              sx={{
                margin: 0,
                padding: 0,
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "baseline",
              }}
            >
              <Box sx={{ pr: 10 }}>{index + 1}.</Box>
              <Box as="span">{name}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const components = new Map([
  ["h2", H2],
  ["h3", H3],
]);

const listComponents = new Map([
  ["ol", Ol],
  ["ul", Ul],
]);
