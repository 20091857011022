import React from "react";
import http from "lib/httpUpload";
import { ReactComponent as DocumentIcon } from "icons/document.svg";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { Box, Text, Input, Spinner } from "theme-ui";
import { useDropzone } from "react-dropzone";

const FileUpload = ({ onChange, maxFiles = 1 }) => {
  const [error, setError] = React.useState();
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setError();

      if (
        acceptedFiles.length === 0 ||
        files.length + acceptedFiles.length > maxFiles
      ) {
        const message = `
Ошибка добавления файлов,
макс. кол-во: ${maxFiles}  файлов`;
        return alert(message.replace(/\n|\r/g, ""));
      }

      setLoading(true);

      for (const file of acceptedFiles) {
        try {
          const data = new FormData();
          data.append("attachFile", file);
          const res = await http("/files/upload", {
            method: "post",
            body: data,
          });

          setFiles((prev) => {
            return prev.concat({
              name: file.name,
              hash: res.data.hash,
            });
          });
        } catch (error) {
          setError(error);
        }
      }

      setLoading(false);
    },
    maxFiles,
  });

  const removeFile = async (hash) => {
    setFiles((prev) => prev.filter((f) => f.hash !== hash));
    return Promise.resolve(
      http(`/files/delete/${hash}`, {
        method: "post",
      })
    );
  };

  const hasUploadedFiles = files.length > 0;
  React.useEffect(() => onChange(files.map((f) => f.hash)), [files]);

  return (
    <Box sx={{ position: "relative", mt: 15, "& *": { outline: "none" } }}>
      <Box {...getRootProps()}>
        <Input {...getInputProps()} />
        <Box
          sx={{
            display: "flex",
            gap: 16,
            cursor: "pointer",
            color: "text",
            transition: "0.2s all",
          }}
        >
          <Box sx={{ flex: "none" }}>
            <DocumentIcon />
          </Box>
          <Box>
            <Text
              as="div"
              sx={{
                mt: 4,
                fontSize: 20,
                lineHeight: 1.2,
                fontWeight: 500,
              }}
            >
              Прикрепить документ
            </Text>
            {hasUploadedFiles && (
              <Box sx={{ my: 15 }}>
                {files.map((file) => (
                  <Box
                    key={file.hash}
                    sx={{ display: "flex", gap: 10, alignItems: "center" }}
                  >
                    <Box
                      onClick={(ev) => {
                        ev.stopPropagation();
                        removeFile(file.hash);
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { color: "text" },
                      }}
                    >
                      <CloseIcon width={11} height={10} />
                    </Box>
                    <Text
                      sx={{
                        color: "gray400",
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: 1.7,
                      }}
                    >
                      {file.name}
                    </Text>
                  </Box>
                ))}
              </Box>
            )}
            {error && (
              <Box sx={{ my: 15 }}>
                <Text variant="caption1" sx={{ color: "red100" }}>
                  Ошибка загрузки файла
                </Text>
              </Box>
            )}
            <Text
              as="div"
              sx={{
                fontSize: 14,
                lineHeight: 1.7,
                fontWeight: 500,
                mt: 15,
                color: "gray400",
              }}
            >
              Вы можете прикрепить файлы в форматах jpg, png, doc, jpeg, pdf.
              Максимальный размер файла — 5 Mb
            </Text>
          </Box>
        </Box>
      </Box>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bg: "rgba(255,255,255,0.5)",
          }}
        >
          <Spinner sx={{ color: "primary" }} size={18} />
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
