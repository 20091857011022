/** @jsxImportSource theme-ui */
import Hr from "components/Hr";
import RouterLink from "components/RouterLink";
import AnimatedImage from "components/AnimatedImage";
import Button from "components/Button";
import ProjectsMap from "components/ProjectsMap";
import { humanDate } from "lib/helpers";
import { useNewsFindAll } from "store/news";
import { useProjects } from "store/projects";
import { ReactComponent as SloganIcon } from "icons/slogan.svg";
import { Box, Text, Image } from "theme-ui";

const Home = () => {
  const { data: news = [] } = useNewsFindAll({ count: 3 });
  const { data: projects = [] } = useProjects();
  const projectsList = projects.slice(0, 2);

  return (
    <Box sx={{ pt: [83, 16], pb: [90, 170] }}>
      <Box sx={{ position: "relative" }}>
        <Box sx={{ display: ["none", "block"] }}>
          <Image
            src={require("images/h1.jpg").default}
            sx={{ display: "block", width: ["100%"], borderRadius: 10 }}
          />
        </Box>
        <Box
          sx={{
            position: ["relative", "absolute"],
            top: 0,
            bottom: 0,
            left: [0, 30],
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Text
              as="div"
              sx={{
                fontSize: [36, 66],
                lineHeight: [1, 1.1],
                fontWeight: 600,
                color: ["text", "black"],
              }}
            >
              Салимгараев
              <br /> Радик
              <br /> Ваккасович
            </Text>
            <Text
              as="div"
              sx={{
                fontSize: [14, 20],
                lineHeight: [1.7, 1.2],
                fontWeight: 500,
                mt: 5,
                color: "gray500",
              }}
            >
              Депутат Казанской городской Думы
            </Text>
          </Box>
        </Box>
        <Box sx={{ display: ["block", "none"], mt: 30, borderRadius: 10 }}>
          <Image
            src={require("images/hm1.jpg").default}
            sx={{ display: "block", width: ["100%"], borderRadius: 10 }}
          />
        </Box>
      </Box>
      <Hr sx={{ mt: [46, 110], mb: [30, 60] }} />
      <Box
        sx={{
          display: ["block", "grid"],
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Box sx={{ gridColumn: "1/7" }}>
          <Text as="div" variant="h3" sx={{ fontSize: 26 }}>
            Дорогие горожане!
          </Text>
          <Text
            as="div"
            variant="text1"
            mt={30}
            sx={{
              color: "gray400",
              maxWidth: ["100%", `${(569 / 1366) * 100}vw`],
            }}
          >
            Этот сайт является одновременно как способом сбора ваших обращений,
            предложений, инициатив, так и формой моего отчёта перед вами.
            <br />
            <br />
            Я открыт к диалогу и благодарен всем, кто проявляет личную
            активность и заинтересованность.
            <br />
            <br />
            Давайте вместе работать на благо нашего района и Казани!
            У нас всё получится!
          </Text>
        </Box>
        <Box
          sx={{
            gridColumn: "8/12",
            mt: [42, 0],
            "& svg": { display: "block", width: "100%", px: [20, 0] },
          }}
        >
          <SloganIcon />
        </Box>
      </Box>
      <Box sx={{ mt: [30, 70], position: "relative" }}>
        <Box sx={{ display: ["none", "block"] }}>
          <AnimatedImage
            ratio={1266 / 432}
            src={require("images/h3.jpg").default}
            animateHover={true}
            imageSx={{ borderRadius: 10 }}
          />
        </Box>
        <Box sx={{ display: ["block", "none"] }}>
          <AnimatedImage
            ratio={1}
            src={require("images/hm3.jpg").default}
            animateHover={true}
            imageSx={{ borderRadius: 10 }}
          />
        </Box>
        <Box
          sx={{
            position: ["relative", "absolute"],
            top: 0,
            left: [0, 60],
            bottom: 0,
            display: "flex",
            alignItems: "center",
            mt: [30, 0],
            color: "black",
          }}
        >
          <Box>
            <Text as="div" variant="h2">
              Есть идея?
            </Text>
            <Text
              as="div"
              variant="text1"
              sx={{ my: 30, mt: [15, 30], color: ["gray400", "black"] }}
            >
              Пишите ваши обращения, предложения,
              <br /> инициативы по улучшению нашего города
            </Text>
            <RouterLink
              href="/feedback"
              sx={{
                display: ["flex", "block"],
                justifyContent: "center",
              }}
            >
              <Button>Напишите мне</Button>
            </RouterLink>
          </Box>
        </Box>
      </Box>
      <Hr sx={{ mt: [100, 200], mb: [30, 60] }} />
      <Box
        sx={{
          display: ["block", "flex"],
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box sx={{ maxWidth: ["100%", 540] }}>
          <Text as="div" variant="h2">
            Многое уже сделано нами, но многое впереди!
          </Text>
          <Text as="div" variant="text1" sx={{ color: "gray400", mt: 15 }}>
            На карте отмечены места, благоустройством которых занималась моя
            команда
          </Text>
        </Box>
      </Box>
      <Box sx={{ mt: [15, 30] }}>
        <ProjectsMap projects={projects} />
      </Box>
      <Box sx={{ mt: [30, 60] }}>
        {projectsList.map((project, index) => (
          <ListItem {...project} key={index} />
        ))}
      </Box>
      <Box
        sx={{
          mt: [30, 60],
          display: "flex",
          justifyContent: ["center", "flex-start"],
        }}
      >
        <RouterLink href="/projects">
          <Button>Все проекты</Button>
        </RouterLink>
      </Box>
      <Hr sx={{ mt: [100, 200], mb: [30, 60] }} />
      <Box
        sx={{
          display: ["block", "grid"],
          gridTemplateColumns: "1fr 1fr",
          gap: 10,
        }}
      >
        <Box sx={{ pr: [0, 50] }}>
          <Text as="div" variant="h2">
            Шуртыгинский
            <br /> избирательный округ № 22
          </Text>
          <Text
            as="div"
            variant="text1"
            sx={{ mt: 30, color: "gray400", maxWidth: ["100%", 522] }}
          >
            В избирательный округ входит часть территории Советского района
            города Казани.
          </Text>
        </Box>
      </Box>
      <Box
        sx={{
          display: ["block", "grid"],
          gridTemplateColumns: "1fr 1fr",
          gap: 10,
          mt: 60,
        }}
      >
        <Box sx={{ pr: [0, 50] }}>
          <Hr />
          {infrastructure[0].map((item, index) => (
            <InfrastructureRow {...item} key={index} />
          ))}
        </Box>
        <Box>
          <Hr sx={{ display: ["none", "block"] }} />
          {infrastructure[1].map((item, index) => (
            <InfrastructureRow {...item} key={index} />
          ))}
        </Box>
      </Box>
      <Hr sx={{ mt: [100, 200], mb: [30, 60] }} />
      <Box
        sx={{
          display: ["block", "flex"],
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Text as="div" variant="h2">
          Новости
        </Text>
      </Box>
      <Box sx={{ mt: [30, 60] }}>
        {news.map((item) => (
          <NewsItem news={item} key={item.id} />
        ))}
      </Box>
      <Box
        sx={{
          mt: [30, 60],
          display: "flex",
          justifyContent: ["center", "flex-start"],
        }}
      >
        <RouterLink href="/news">
          <Button>Все новости</Button>
        </RouterLink>
      </Box>
    </Box>
  );
};

const infrastructure = [
  [
    {
      name: "Жителей",
      value: "56 461",
    },
    {
      name: "Детских садов",
      value: "16",
    },
    {
      name: "Школ",
      value: "9",
    },
  ],
  [
    {
      name: "Многоквартирных домов",
      value: "81,5%",
    },
    {
      name: "Частный сектор",
      value: "18,5%",
    },
  ],
];

const NewsItem = ({ news }) => (
  <RouterLink
    href={`/news/${news.id}`}
    sx={{ ":hover": { textDecoration: "none" } }}
  >
    <Box
      sx={{
        display: ["block", "grid"],
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 10,
        py: 30,
        border: "2px solid",
        borderLeft: "none",
        borderRight: "none",
        borderColor: "gray300",
        mt: -2,
        "&:hover": {
          position: "relative",
          zIndex: 1,
          borderColor: "text",
        },
      }}
    >
      <Box sx={{ gridColumn: "1/9" }}>
        <Text
          as="div"
          variant="text1"
          sx={{ display: ["block", "none"], color: "gray400", mb: 15 }}
        >
          {humanDate(news.publishedAt)}
        </Text>
        <Text as="div" variant="h3">
          {news.name}
        </Text>
        <Text
          as="div"
          variant="text1"
          sx={{
            mt: [15, 10],
            color: "gray400",
            overflow: ["initial", "hidden"],
            height: 50,
            display: ["block", "-webkit-box"],
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            whiteSpace: "pre-wrap",
          }}
        >
          {news.annotation}
        </Text>
      </Box>
      <Box sx={{ gridColumn: "11/13", display: ["none", "block"] }}>
        <Text as="div" variant="text1">
          {humanDate(news.publishedAt)}
        </Text>
      </Box>
    </Box>
  </RouterLink>
);

const InfrastructureRow = ({ name, value }) => (
  <>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        py: [15, 30],
      }}
    >
      <Text as="div" variant="h3">
        {name}
      </Text>
      <Text as="div" variant="h3">
        {value}
      </Text>
    </Box>
    <Hr />
  </>
);

const ListItem = ({ name, annotation, id }) => (
  <RouterLink href={`/projects/${id}`}>
    <Box
      sx={{
        display: ["block", "grid"],
        gap: 10,
        gridTemplateColumns: "repeat(2, 1fr)",
        py: [30, 26],
        border: "2px solid",
        borderLeft: "none",
        borderRight: "none",
        borderColor: "gray300",
        mt: -2,
        "&:hover": {
          position: "relative",
          zIndex: 1,
          borderColor: "text",
        },
      }}
    >
      <Text as="div" variant="h3" sx={{ pr: 50 }}>
        {name}
      </Text>
      <Text
        as="div"
        variant="text1"
        sx={{
          color: "gray400",
          overflow: "hidden",
          height: 50,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          whiteSpace: "pre-wrap",
          mt: [15, 0],
        }}
      >
        {annotation}
      </Text>
    </Box>
  </RouterLink>
);

export default Home;
