/** @jsxImportSource theme-ui */
import Breadcrumbs from "components/Breadcrumbs";
import ContentBuilder from "components/ContentBuilder";
import Seo from "components/Seo";
import { useBiography } from "store/biography";
import { Box, Text } from "theme-ui";
import { safeJSONParse } from "lib/helpers";

const Biography = () => {
  const { data: biography = [], meta = {} } = useBiography();
  const content = safeJSONParse(biography?.content) ?? [];

  const breadcrumbs = [
    {
      name: biography.name,
      href: "/biography",
    },
  ];

  return (
    <Box sx={{ pt: [83, 45] }}>
      <Seo seo={meta.seo} />
      <Breadcrumbs path={breadcrumbs} />
      <Box sx={{ mb: [60, 40], mt: [0, 30] }}>
        <Text variant="h1" as="h1">
          {biography.name}
        </Text>
      </Box>
      <Box sx={{ mt: 60 }}>
        {biography.content && (
          <ContentBuilder blocks={content} projectId={biography.id} />
        )}
      </Box>
    </Box>
  );
};

export default Biography;
