import { Box, Text, Input, Textarea } from "theme-ui";

const TextField = ({ multiline, error, ...props }) => {
  return (
    <Box sx={{ position: "relative" }}>
      {multiline && (
        <Textarea
          sx={{ ...textFieldSx, ...(error ? errorSx : {}) }}
          {...props}
        />
      )}
      {!multiline && (
        <Input sx={{ ...textFieldSx, ...(error ? errorSx : {}) }} {...props} />
      )}
      {error && <Text sx={{ color: "red100", fontSize: 12 }}>{error}</Text>}
    </Box>
  );
};

const textFieldSx = {
  fontFamily: '"Inter",sans-serif',
  boxShadow: "none",
  borderRadius: 10,
  border: "2px solid",
  borderColor: "gray300",
  fontSize: 14,
  fontWeight: 500,
  color: "text",
  outline: "none",
  py: [13, 10],
  px: 20,
  m: 0,
  "&::placeholder": {
    color: "gray400",
  },
  "&:focus::placeholder": {
    color: "text",
  },
};

const errorSx = {
  borderColor: "red100",
  color: "red100",
  "&:focus": {
    borderColor: "gray300",
    color: "gray400",
  },
  "&::placeholder": {
    color: "red100",
  },
};

export default TextField;
