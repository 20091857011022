/** @jsxImportSource theme-ui */
import Breadcrumbs from "components/Breadcrumbs";
import RouterLink from "components/RouterLink";
import Seo from "components/Seo";
import ProjectsMap from "components/ProjectsMap";
import { Box, Text } from "theme-ui";
import { useProjects } from "store/projects";

const ProjectList = () => {
  const { data: projects = [], meta = {} } = useProjects();

  const breadcrumbs = [
    {
      name: "Проекты",
      href: "/projects",
    },
  ];

  return (
    <Box sx={{ pb: [70, 170], pt: [83, 45] }}>
      <Seo seo={meta.seo} />
      <Breadcrumbs path={breadcrumbs} />
      <Box sx={{ mt: [0, 30] }}>
        <Text variant="h1" as="h1">
          Проекты
        </Text>
      </Box>
      <Box variant="layout.grid12" sx={{ mt: 30 }}>
        <Text
          as="div"
          sx={{ gridColumn: "1/6", color: "gray400" }}
          variant="h3"
        >
          Инициированы и реализованы при поддержке депутата Казанской городской
          Думы Салимгараева Р.В.
        </Text>
      </Box>
      <Box sx={{ mt: [15, 60], overflow: "hidden", borderRadius: 10 }}>
        <ProjectsMap projects={projects} />
      </Box>
      <Box sx={{ mt: [100, 200] }}>
        <Text variant="h2">Верьте только делам!</Text>
      </Box>
      <Box sx={{ mt: [32, 62] }}>
        {projects.map((project) => (
          <RouterLink href={`/projects/${project.id}`} key={project.id}>
            <Box
              sx={{
                borderTop: "2px solid",
                borderBottom: "2px solid",
                borderColor: "gray300",
                mt: -2,
                "&:hover": {
                  borderColor: "text",
                  zIndex: 10,
                  position: "relative",
                },
              }}
            >
              <Box variant="layout.grid12" sx={{ py: 30 }}>
                <Text
                  variant="text1"
                  as="div"
                  sx={{ gridColumn: "1/3", color: "gray400" }}
                >
                  {project.address}
                </Text>
                <Text
                  as="div"
                  sx={{ gridColumn: "3/7", mt: [15, 0] }}
                  variant="h3"
                >
                  {project.name}
                </Text>
                <Text
                  as="div"
                  sx={{
                    gridColumn: "7/13",
                    color: "gray400",
                    mt: [15, 0],
                    overflow: "hidden",
                    height: 50,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    whiteSpace: "pre-wrap",
                  }}
                  variant="text1"
                >
                  {project.annotation}
                </Text>
              </Box>
            </Box>
          </RouterLink>
        ))}
      </Box>
    </Box>
  );
};

export default ProjectList;
